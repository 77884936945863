import React from 'react';
import './App.css';
import constructionGif from './construction.gif'; // Ensure the GIF is located in the src folder

function App() {
  return (
    <div className="App">
      <div className="App-header">
        <h1 className="Title">Cake Princess</h1>
        <img src={constructionGif} alt="Construction" className="Construction-image"/>
      </div>
    </div>
  );
}

export default App;
